import React from "react";
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';
import Header from "../components/header.js";
import "../sass/norihall.scss";

export const query = graphql`
  {
    prismic {
		  shows_awards_grants(uid:"shows-awards-grants", lang:"en-us") {
		    title
        gallery_shows
        artist_residency
        awards
        grants
		  }
    }
  }
`


function ShowsAwardsGrantsPage({ data }) {
  return (
    <>
      <Header />
      <div id="content">
        {RichText.render(data.prismic.shows_awards_grants.title)}
        <div id="contentShowsAwardsGrants">
          <div className="shows">
            <h3>Gallery Shows</h3>
            {RichText.render(data.prismic.shows_awards_grants.gallery_shows)}
          </div>
          <div className="awards-grants">
            <h3>Artist Residency</h3>
            {RichText.render(data.prismic.shows_awards_grants.artist_residency)}

            <h3>Awards</h3>
            {RichText.render(data.prismic.shows_awards_grants.awards)}

            <h3>Grants</h3>
            {RichText.render(data.prismic.shows_awards_grants.grants)}
          </div>
        </div>       


      </div>
    </>
  )
}

export default ShowsAwardsGrantsPage