import React from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import { StaticQuery, graphql } from 'gatsby';

export default () => (
	<StaticQuery
		query={graphql`
		  {
		    prismic {
		      home(uid: "home", lang: "en-us") {
		        galleries {
		          gallery {
		            ... on PRISMIC_Gallery {
		              title
		              _meta {
		                uid
		              }
		            }
		          }
		        }
		      }
		    }
		  }
		`}
		render={data => (
			<div id="nh-header">
				<div className="branding">
					<TransitionLink 
						to="/" 
	          exit={{
	            length: 0.75
	          }}
	          entry={{
	            delay: 0.1
	          }}
						className="branding"
					>
						Nori Hall
					</TransitionLink>
				</div>
				<div className="galleriesNav">
					<span className="nav-menu-label">Galleries</span>
					<ul>
						{
							data.prismic.home.galleries.map(function(gallery, index) {
						    const galleryURL = '/gallery/' + gallery.gallery._meta.uid;
								return (
						      <li key={index}>
							      <TransitionLink 
								      to={galleryURL}								      
						          exit={{
						            length: 0.75
						          }}
						          entry={{
						            delay: 0.1
						          }}
							      >
								      {gallery.gallery.title}
							      </TransitionLink>
						      </li>
								)
							})
						}
					</ul>
				</div>
				<div>
					<TransitionLink 
						to="/about"
	          exit={{
	            length: 0.75
	          }}
	          entry={{
	            delay: 0.1
	          }}
					>
						About
					</TransitionLink>
				</div>
				<div>
					<TransitionLink 
						to="/shows_awards_grants"
	          exit={{
	            length: 0.75
	          }}
	          entry={{
	            delay: 0.1
	          }}
					>
						Shows | Awards | Grants
					</TransitionLink>
				</div>
			</div>
		)}
	/>
)